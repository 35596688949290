<template>
  <b-container fluid>
    <b-row>
      <b-spinner
        label="Loading..."
        v-if="loading"
      ></b-spinner>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col>
        <b-table
          id="table"
          :fields="fields"
          :items="visits"
          responsive
          hover
          @row-clicked="goToVisit"
        >
          <template v-slot:cell(patient)="data">
            <div v-if="data.item.lastName || data.item.firstName">
              {{ data.item.lastName }} {{ data.item.firstName }},
              {{ getAge(data.item.birthDate) }} lat <template
                v-if="data.item.months && getAge(data.item.birthDate) < 18"
              >
                {{
                  data.item.months % 12 }}
                miesięcy</template>
              <b-button
                v-if="data.item.comment.trim()"
                @click="openCommentModal(data.item.comment)"
                v-b-modal.comment-modal
                variant="outline"
              ><font-awesome-icon icon="comment-dots" />
              </b-button>
            </div>
          </template>
          <template v-slot:cell(dateStart)="data">
            <div style="display: flex; align-items: center">
              {{ data.value | formatDateVisit }}
              <font-awesome-icon
                icon="check"
                v-if="data.item.confirmed"
                style="margin-left: 5px"
              ></font-awesome-icon>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <div v-if="data.item.epid_status">
              <div
                :class="`led led-${epidColors[data.item.epid_status]}`"
                :title="$t('visitsList.epidStatus')"
              ></div>
              <div
                :title="$t('visitsList.insurance')"
                :class="`led led-${data.item.ewus === 1 ? 'green' : 'red'}`"
              ></div>
              <div
                class="led led-green"
                :title="$t('visitsList.insertedCheckup')"
                v-if="checkIsNewCheckup(data.item.lastCheckupDate)"
              ></div>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <div>
              <actions
                v-if="data.item.disabled"
                :data="data"
              />

              <actions
                v-if="!data.item.disabled"
                :data="data"
                @go-to-visit="goToVisit($event)"
                @set-visit="setVisit($event)"
                @go-to-patient="goToPatient($event)"
                @edit-patient="editPatient($event)"
                @set-phone-number="setPhoneNumber($event)"
                @change-visit-type="handleClickEditVisitType($event)"
              />
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      id="comment-modal"
      hide-footer
    >
      {{ comment }}
    </b-modal>
    <b-modal id="contact-modal">
      <a :href="`phone:${phoneNumber}`">{{ phoneNumber }}</a>
    </b-modal>
    <modal
      :data="editedPatient"
      modal-id="edit-patient"
      @submit="updatePatient($event)"
      @visit="updatePatientAndStartVisit($event)"
      @visit-date="updatePatientAndSetVisit($event)"
    />
    <calendar-modal :patient-id="plannedPatientId" />
    <b-modal
      id="visit-type-modal"
      hide-footer
    >
      <h4>{{ $t('visitsList.chooseVisitType') }}</h4>
      <b-button @click="handleUpdateVisitType('wizyta')">{{
        $t('visitsList.gabinet')
        }}</b-button>
      <b-button
        @click="handleUpdateVisitType('telewizyta')"
        variant="info"
      >{{
        $t('visitsList.televisit')
        }}</b-button>
      <b-button
        @click="handleUpdateVisitType('recepta')"
        variant="success"
      >{{
        $t('visitsList.recipe')
        }}</b-button>
    </b-modal>
  </b-container>
</template>
<script>
import patientService from '../../services/patients'
import visitService from '../../services/visits'
import { mapState, mapActions } from 'vuex'
import Modal from '../Patient/Modal'
import CalendarModal from '../Patient/CalendarModal'
import Actions from './Actions.vue'
import moment from 'moment'
let interval = null
export default {
  components: {
    Modal,
    CalendarModal,
    Actions
  },
  data () {
    return {
      phoneNumber: '',
      loading: false,
      editedPatient: {},
      visitIdToUpdate: null,
      visitTypes: {
        wizyta: 'G',
        telewizyta: 'T',
        recepta: 'R'
      },
      visitVariants: {
        wizyta: '',
        telewizyta: 'info',
        recepta: 'success'
      },
      epidColors: {
        brak: 'grey',
        wysłane: 'grey',
        pozytywny: 'red',
        negatywny: 'green'
      },
      visitDate: '',
      statusEwusTexts: ['Nieubezpieczony', 'Ubezpieczony', 'Nieznany'],
      comment: ''
    }
  },
  computed: {
    ...mapState('Visit', {
      visits (state) {
        const vm = this
        return state.visits.rows.map((el) => ({
          ...el,
          _rowVariant: vm.visitVariants[el.visitType]
        }))
      },
      filters: (state) => state.filters
    }),
    ...mapState('Patient', {
      plannedPatientId (state) {
        return state.plannedPatientId
      }
    }),
    isToday () {
      return (
        moment().format('YYYY-MM-DD') ===
        moment(this.visitDate).format('YYYY-MM-DD')
      )
    },
    fields () {
      return [
        {
          key: 'dateStart',
          label: this.$t('visitsList.dateField')
        },
        {
          key: 'patient',
          label: this.$t('visitsList.patientField')
        },
        {
          key: 'status',
          label: this.$t('visitsList.epidField')
        },
        {
          key: 'id',
          label: this.$t('visitsList.actionField'),
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        }
      ]
    }
  },
  mounted () {
    this.visitDate = moment().format('YYYY-MM-DD')
    interval = setInterval(() => this.fetchVisits(), '10000')
  },
  beforeDestroy () {
    clearInterval(interval)
  },
  methods: {
    ...mapActions('Visit', ['setFilters', 'fetchVisits']),
    ...mapActions('Patient', ['setPlannedPatientId']),
    handleChangeDate (value) {
      this.visitDate = moment(value).format('YYYY-MM-DD')
      this.setFiltersState('date', value)
    },
    setFiltersState (prop, value) {
      this.setFilters({ prop, value })
      this.fetchVisits()
    },
    getAge (date) {
      if (date) {
        return moment().diff(date, 'years')
      } else {
        return ''
      }
    },
    goToVisit (item) {
      if (item.patientId) {
        this.$router.push(`/app/pacjenci/${item.patientId}/wizyta/${item.id}?start=true`)
      }
    },
    goToPatient (item) {
      this.$router.push(`/app/pacjenci/${item.patientId}`)
    },
    openCommentModal (text) {
      this.comment = text
    },
    setPhoneNumber (number) {
      this.phoneNumber = number
    },
    editPatient (patient) {
      this.editedPatient = JSON.parse(JSON.stringify(patient))
      this.$bvModal.show('edit-patient')
    },
    checkIsNewCheckup (checkupDate) {
      return moment().diff(checkupDate, 'days') === 0
    },
    async updatePatient (patient) {
      try {
        this.$showLoader()
        await patientService.updatePatient(patient)
        this.fetchVisits()
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visitsList.updateUserError'),
          'error'
        )
      } finally {
        this.$hideLoader()
      }
    },
    async updatePatientAndStartVisit (patient) {
      await this.updatePatient(patient)
      await this.startVisit(patient.id)
    },
    async updatePatientAndSetVisit (patient) {
      await this.updatePatient(patient)
      this.setPlannedPatientId(patient.id)
      this.$bvModal.show('calendar-modal')
    },
    async startVisit (patientId) {
      const start = moment().set({ minute: 0 }).format('YYYY-MM-DD HH:mm')
      const end = moment()
        .set({ minute: 0 })
        .add(30, 'minutes')
        .format('YYYY-MM-DD HH:mm')
      const { data } = await visitService.sendNextDayVisit({
        patientId,
        start,
        end,
        adhoc: true
      })
      this.$router.push(`/app/pacjenci/${patientId}/wizyta/${data.visitId}`)
    },
    setVisit (item) {
      this.setPlannedPatientId(item.id)
      this.$bvModal.show('calendar-modal')
    },
    handleClickEditVisitType (visitId) {
      this.visitIdToUpdate = visitId
    },
    async handleUpdateVisitType (visitType) {
      try {
        await visitService.updateVisitType(this.visitIdToUpdate, visitType)
        this.visitIdToUpdate = null
        this.fetchVisits()
        this.$swal(
          this.$t('success'),
          this.$t('visitsList.successUpdateVisitType'),
          'success'
        )
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('visitsList.updateVisitTypeError'),
          'error'
        )
      } finally {
        this.visitIdToUpdate = null
        this.$bvModal.hide('visit-type-modal')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.visit-type {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 700;
}

.visit-icon {
  background-color: #089bab;
  border-radius: 50%;
  color: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.first-visit {
  color: #fff;
  background: #089bab;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.led {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 5%;
  margin-right: 2px;
  display: inline-block;
}

.led-green {
  background: green;
}

.led-red {
  background: red;
}

.led-grey {
  background: grey;
}

.row {
  cursor: pointer;
}

.btn:hover {
  background: #089bab;
}
</style>
