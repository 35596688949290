<template>
  <div v-if="refreshed">
    <router-view />
  </div>
</template>
<script>
import auth from './services/auth'

export default {
  name: 'App',
  data () {
    return {
      refreshed: false
    }
  },
  beforeMount () {
    auth.refreshToken().finally(() => {
      this.refreshed = true
    })
  }
}
</script>
<style lang="scss">
@import 'assets/scss/style.scss';

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.prescription tr td {
  color: #000 !important;
}

.prescription-table td:first-child {
  font-weight: 700;
  min-width: 200px;
}

.carousel-indicators li,
.carousel-control-next span,
.carousel-control-prev span {
  background-color: #31c02c !important;
  opacity: 0.8 !important;
}

.carousel-indicators .active {
  opacity: 1 !important;
}

.form-control {
  color: #000 !important;

  @media screen and (max-width: 1400px) {
    font-size: 16px !important;
  }
}
</style>
