<template>
  <b-modal :id="modalId" @show="initData()" size="xl">
    <template #modal-title>{{ $t('patient.patient') }} </template>
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-form-group :label="$t('patient.validationType')">
            <b-form-select
              v-model="validationType"
              :options="patientTypeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.firstName')">
            <b-form-input
              v-model="$v.editedPatient.firstName.$model"
              :state="validateState('firstName')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.lastName')">
            <b-form-input
              v-model="$v.editedPatient.lastName.$model"
              :state="validateState('lastName')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.sex')">
            <b-form-select
              v-model="$v.editedPatient.sex.$model"
              :options="sexOptions"
              :state="validateState('sex')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.phone')">
            <b-form-input
              v-model="$v.editedPatient.phone.$model"
              :state="validateState('phone')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.email')">
            <b-form-input
              v-model="$v.editedPatient.email.$model"
              type="email"
              :state="validateState('email')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="PESEL">
            <b-form-input
              v-model="$v.editedPatient.pesel.$model"
              :state="validateState('pesel')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.city')">
            <b-form-input
              v-model="$v.editedPatient.city.$model"
              :state="validateState('city')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.address')">
            <b-form-input
              v-model="$v.editedPatient.address.$model"
              :state="validateState('address')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="TERYT">
            <b-form-input
              v-model="$v.editedPatient.teryt.$model"
              :state="validateState('teryt')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.postalCode')">
            <b-form-input
              v-model="$v.editedPatient.postalCode.$model"
              :state="validateState('postalCode')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="NFZ">
            <b-form-select
              v-model="$v.editedPatient.nfz.$model"
              :options="nfzOptions"
              :state="validateState('nfz')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.document')">
            <b-form-input v-model="$v.editedPatient.document.$model" />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group :label="$t('patient.authorizedPerson')">
            <b-form-input v-model="$v.editedPatient.authorizedPerson.$model" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('patient.interview')">
            <b-button
              v-for="(el, i) in getPreliminaryInterviewText"
              :key="`${el.descriptionType}-${i}`"
              :title="el.text"
              variant="light"
              size="small"
              @click="editedPatient.interview += el.text"
              >{{ el.text.substr(0, 10)
              }}{{ el.text.length > 10 ? '...' : '' }}</b-button
            >
            <b-form-textarea
              v-model="$v.editedPatient.interview.$model"
              rows="3"
              max-rows="100"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('patient.comment')">
            <b-form-textarea
              v-model="$v.editedPatient.comment.$model"
              rows="3"
              max-rows="100"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <template #modal-footer>
      <b-button @click="handleSubmit()">{{ $t('patient.save') }}</b-button>
      <b-button @click="handleVisit()">{{
        $t('patient.saveAndStartVisit')
      }}</b-button>
      <b-button @click="handleVisitDate()">{{
        $t('patient.saveAndPlanVisit')
      }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatePolish } from 'validate-polish'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    modalId: {
      type: String,
      default: 'modal'
    },
    data: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      patientTypeOptions: [
        {
          value: 'full',
          text: 'Pełna walidacja'
        },
        {
          value: 'basic',
          text: 'Brak walidacji'
        }
      ],
      validationType: 'full',
      editedPatient: {
        firstName: '',
        lastName: '',
        sex: '',
        phone: '',
        email: '',
        pesel: '',
        city: '',
        address: '',
        teryt: '',
        postalCode: '',
        nfz: '',
        document: '',
        authorizedPerson: '',
        interview: '',
        patientType: 'obywatel_polski',
        comment: ''
      },
      sexOptions: [
        {
          text: 'Mężczyzna',
          value: 'M'
        },
        {
          text: 'Kobieta',
          value: 'K'
        }
      ],
      nfzOptions: [
        {
          text: 'Dolnośląski',
          value: 1
        },
        {
          text: 'Kujawsko-Pomorski',
          value: 2
        },
        {
          text: 'Lubelski',
          value: 3
        },
        {
          text: 'Lubuski',
          value: 4
        },
        {
          text: 'Łódzki',
          value: 5
        },
        {
          text: 'Małopolski',
          value: 6
        },
        {
          text: 'Mazowiecki',
          value: 7
        },
        {
          text: 'Opolski',
          value: 8
        },
        {
          text: 'Podkarpacki',
          value: 9
        },
        {
          text: 'Podlaski',
          value: 10
        },
        {
          text: 'Pomorski',
          value: 11
        },
        {
          text: 'Śląski',
          value: 12
        },
        {
          text: 'Świętokrzyski',
          value: 13
        },
        {
          text: 'Warmińsko-Mazurski',
          value: 14
        },
        {
          text: 'Wielkopolski',
          value: 15
        },
        {
          text: 'Zachodniopomorski',
          value: 16
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Visit', ['getPreliminaryInterviewText'])
  },
  validations() {
    switch (this.validationType) {
      case 'full':
        return {
          editedPatient: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            sex: {
              required
            },
            phone: {},
            email: {},
            address: {
              required
            },
            teryt: {
              required
            },
            city: {
              required
            },
            pesel: {
              required,
              validatePesel: (value) => validatePolish.pesel(value)
            },
            postalCode: {
              required
            },
            nfz: {
              required
            },
            patientType: {
              required
            },
            document: {},
            authorizedPerson: {},
            interview: {},
            comment: {}
          }
        }
      case 'basic':
        return {
          editedPatient: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            sex: {
              required
            },
            phone: {},
            email: {},
            address: {},
            teryt: {},
            city: {},
            pesel: {},
            postalCode: {},
            nfz: {},
            patientType: {
              required
            },
            document: {},
            authorizedPerson: {},
            interview: {},
            comment: {}
          }
        }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.editedPatient[name]
      return $dirty ? !$error : null
    },
    initData() {
      this.$nextTick(() =>
        this.data
          ? (this.editedPatient = JSON.parse(JSON.stringify(this.data)))
          : null
      )
    },
    handleSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('submit', this.editedPatient)
        this.$bvModal.hide(this.modalId)
      }
    },
    handleVisit() {
      if (!this.$v.$invalid) {
        this.$emit('visit', this.editedPatient)
        this.$bvModal.hide(this.modalId)
      }
    },
    handleVisitDate() {
      if (!this.$v.$invalid) {
        this.$emit('visit-date', this.editedPatient)
        this.$bvModal.hide(this.modalId)
      }
    }
  }
}
</script>
