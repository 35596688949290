<template>
  <b-container fluid>
    <b-row class="detail-wrapper">
      <b-col :sm="12" id="visits">
        <visit />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Visit from '../Visit/Visit/index'
export default {
  components: {
    Visit
  },
  computed: {
    ...mapState('Setting', {
      justify: (state) => state.patientJustify
    }),
    ...mapGetters('Patient', ['getVisitsToTimeLine'])
  },
  data() {
    return {
      size: 'both'
    }
  },
  async beforeMount() {
    await this.getPatientData()
    if (this.getVisitsToTimeLine && this.getVisitsToTimeLine.length > 0) {
      const { id, type } = this.getVisitsToTimeLine[0]
      this.setSelectedVisit({ id, type })
    }
    this.$refs.addVisit.init()
  },
  methods: {
    ...mapActions('Patient', ['getPatient', 'setSelectedVisit']),
    async getPatientData() {
      const patientId = this.$route.params.id
      let patientVisitId = null
      if (this.$route.params.visit) {
        patientVisitId = this.$route.params.visit
      }
      await this.getPatient({ id: patientId, selectedVisitId: patientVisitId })
    }
  }
}
</script>
<style scoped>
.transition {
  transition: 0;
  padding-top: -0.75rem;
}
</style>
